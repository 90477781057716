@font-face {
  font-family: 'Inter';
  font-weight: 700; /* Толщина шрифта: обычная */
  src: url('../../public/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype'); /* Путь к файлу шрифта TTF */
}

.footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 100px;
    background: rgba(242, 246, 247, 1);
    flex: 0 0 auto;
  }

  .footer-group {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 1314px;
  }
  .contact-info-container1 {
    box-sizing: border-box;
    width: 25%;
  }
  .contact-header-text-style {
    font: 700 23px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
    margin: 0;
  }
  .contact-info-container {
    box-sizing: border-box;
    width: 100%;
  }
  .contact-info-heading {
    font: 700 14.5px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .contact-info-block {
    box-sizing: border-box;
    max-width: 123px;
    margin-top: 15px;
    margin-left: 0;
    font: 400 13px/18px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .contact-details-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
  }
  .contact-info-style {
    font: 400 13px/18px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: left;
    white-space: pre-wrap;
    margin-left: 0;
  }

  .contact-info-style a:hover {
    color: #424344;
  }

  .contact-info-style a {
    text-decoration: none;
    color: rgba(88, 96, 102, 1);
    transition-duration: 0.7s;
  }

  .contact-info-style:nth-child(n+2) {
    margin-top: 20px;
  }

  .contact-info-style_1 {
    font: 400 13px/18px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: left;
    white-space: pre-wrap;
    margin-left: 0;
  }

  .contact-info-style_1 a {
    text-decoration: none;
    color: rgba(88, 96, 102, 1);

  }

  .contact-info-style_1:nth-child(n+2) {
    margin-top: 10px;
  }

  .footer_under_group {
    width: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  .product-title-info {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 25px;
  }

  .corporate-contact-card {
    box-sizing: border-box;
    width: 100%;
    margin-top: 72px;
  }
  .corporate-client-director-text-style {
    margin-top: 12px;
    font: 400 13px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .corporate-client-service-lead-text-style {
    margin-top: 23px;
    font: 400 13.5px/18px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .product-info-container1 {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .footer_group_selection {
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: space-between;
  }
  .product-title-style {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
    font: 700 23.5px/28px Inter, sans-serif;
    font-weight: bolder;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .product-title-bold {
    flex: 0 0 auto;
    margin-top: 307px;
    font: 700 14.5px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .company-info-container1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 25%;
  }
  .company-heading-text-style {
    font: 700 23.5px/28px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .company-info-heading {
    font: 400 13.5px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .company-info-text {
    margin-top: 20px;
    font: 400 13.5px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .news-heading1 {
    margin-top: 20px;
    font: 400 13px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .main-content-container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }

  .main-content-container-left {
    margin-left: 0;
    margin-right: 0;
  }

  .main-content-container-right {
    margin-left: 3%;

    margin-right: 0;
   
  }
  .flex-container-with-padding {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    
  }
  .flex-container-with-padding a {
    margin: 0;
  }

  .flex-container-with-padding a:nth-child(n+2) {
    margin-left: 5%;
  }

  .flex-container-with-padding a img {
    width: 34px;
    height: 34px;
  }

  
  .horizontal-flex-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .svg-container3 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(196, 203, 209, 1);
  }
  .horizontal-icon-group {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 84px;
  }
  .svg-container4 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(111, 63, 170, 1);
  }
  .informational-text-style {
    margin-top: 16px;
    font: 400 13px/16px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .company-copyright-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 15%;
    margin-right: 0;
  }

  .company-copyright-section a {
    margin: 0;
  }

  .personal-data-policy-text-style a {
    text-decoration: underline;
    color:rgba(144, 151, 156, 1);
    transition-duration: 0.7s;

  }

  .personal-data-policy-text-style a:hover {
    color: #424344;
  }

  .copyright-logo {
    box-sizing: border-box;
    display: block;
    max-width: initial;
    height: 38px;
    border: none;
    object-fit: cover;
    margin: 0;
  }
  .copyright-text-display-style {
    margin-right: 0;
    margin-left: 5%;
    flex: 0 0 auto;
    font: 400 13px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    white-space: pre-wrap;
  }
  .personal-data-policy-text-style {
    margin-top: 11px;
    font: 400 13px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    text-decoration-line: underline;
    white-space: pre-wrap;
  }
  .sidebar-widget {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 25%;
  }
  .vertical-content-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
  }
  .content-section-title {
    flex: 0 0 auto;
    font: 400 13px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .media-policy-styles {
    flex: 0 0 auto;
    font: 400 13.5px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .payment-options-section {
    box-sizing: border-box;
    margin: 0;
  }
  .payment-notice-text-style {
    box-sizing: border-box;
    width: 100%;
    font: 400 15.5px/20px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .card-payment-info-block {
    box-sizing: border-box;
    display: block;
    max-width: initial;
    height: 68px;
    margin: 0;
  }

  .main-content-container-left-logo {
    display: flex;
    flex-direction: row;
  }

  .copyright-logo_mob {
    display: none;
  }

  @media (max-width: 768px) {
    .footer-group {
      width: 92%;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
    }

    .footer_group_selection {
      flex-direction: column;
    }

    .contact-header-text-style {
      font: 700 22px Inter, sans-serif;
    }

    .product-title-style {
      font: 700 22px Inter, sans-serif;
    }
    .contact-info-heading {
      font: 700 20px Inter, sans-serif;
    }

    .contact-info-style_1 {
      font: 400 18px / 26px Inter, sans-serif;
    }

    .contact-info-style {
      font: 400 18px / 28px Inter, sans-serif;
    }
    .product-info-container1 {
      margin-top: 8%;
    }
    .contact-details-section {
      margin-top: 10%;
    }

    .contact-info-container {
      margin-top: 1%;
    }

    .main-content-container {
      flex-direction: column;
    }

    .flex-container-with-padding a img {
      width: 100%;
      height: 50%;
    }

    .copyright-logo {
      width: 160px;
      height: 180px;
      display: none;
    }

    .copyright-logo_mob {
      display: flex;
      width: 60px;
      height: auto;
    }

    .main-content-container-left-logo {
      flex-direction: column;
      
    }

    .company-copyright-section {
      margin-left: 0;
      justify-content: flex-start;
      margin-top: 5%;
      width: 100%;
      
    }
    .copyright-text-display-style {
      font: 400 16px Inter, sans-serif;
    }

    .main-content-container-left {
      margin-top: 3%;
    }

    .flex-container-with-padding a {
      width: 150px;
        height: 100%
    }

    .flex-container-with-padding {
      width: 150px;
        
    }

    .informational-text-style {
      font: 400 14px / 16px Inter, sans-serif;
      margin-top: 3%;
      margin-bottom: 5%;
    }
  }