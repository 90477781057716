


  .fullwidth-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  .svg-container {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(23, 33, 38, 1);
    margin-left: 0;
    margin-right: 0;
  }

  .svg-container a img {
    width: 24px;
    height: 24px;

  }

  .product-card-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .news-card-link {
    width: 45%;
    margin-left: 0;
    margin-right: 0;
    text-decoration: none;;
  }

  .svg-container1 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-left: 0;
    margin-right: 0;
  }

  .product-search-container {
    box-sizing: border-box;
    width: 100%;
    margin-top: 24px;
  }
  .inner-content-wrapper {
    padding-right: 13px;
    padding-left: 13px;
  }
  .fullwidth-border-top-box {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #90979c;
  }
  .negative-margin-top {
    margin-top: -18px;
  }
  .image-container {
    box-sizing: border-box;
    display: block;
    width: 538px;
    max-width: initial;
    height: 49px;
  }
  .product-search-prompt {
    padding-right: 48px;
    padding-left: 49px;
    margin-top: -8px;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .product-description-text-style {
    font: 400 20px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    text-align: left;
  }
  .highlighted-text {
    font: 400 20px Inter, sans-serif;
    color: rgba(244, 125, 50, 1);
    text-align: left;
  }
  .product-catalog-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 110px;
  }
  .catalog-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .catalog-title {
    font: 700 40px/36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }
  

  .product-card-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5%;
  }

  .product-card-list-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-card-list-line-entity {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-decoration: none;
    
  }

  .product-card-list-line a  {
    margin-left: 0;
    margin-right: 0;
    width: 45%;
    text-decoration: none;
  }

  .product-card-list-line-entity-card {
    background-color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-size:  100%;
    background-position: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 290px;
    justify-content: space-between;
    position: relative; 
  }

  .product-card-list-line-entity-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 0.95);
    border-radius: 12px;
    
    transition: opacity 0.3s ease;
}

.product-card-list-line-entity-card:hover::after {
    opacity: 0; 
}

.product-card-list-line-entity-card h4,
.product-card-list-line-entity-card h3,
.product-card-list-line-entity-card p {
    position: relative;
    z-index: 1;
    color: white; 
}

.product-card-list-line-entity-card:hover h4,
.product-card-list-line-entity-card:hover h3,
.product-card-list-line-entity-card:hover p {
    opacity: 0; 
    transition: opacity 0.3s ease;
}

  .product-card-list-line-entity-card h4, .product-card-list-line-entity-card h3 {
    margin-left: 4%;
    margin-right: 4%;
    color: white;
    font-weight: bold;
    font-family: Inter, sans-serif;
  }

  .product-card-list-line-entity-card h4 {
    margin-top: 3%;
  }

  .product-card-list-line-entity-card h3 {
    margin-bottom: 3%;
    font-size: 30px;
  }

  .product-card-list-line-entity p {
    font-family: Inter, sans-serif;
    font-weight: bolder;
    margin-left: 2%;
    margin-right: 2%;
    line-height: 1.6;
    color: #696B6C;
    margin-top: 2%;
  }

  .product-card-list-line-entity p {
    transition: margin-top 0.3s ease; /* Анимация изменения верхнего отступа текста */
}

  .product-card-list-line-entity:hover p {
    margin-top: 5%; /* При наведении на контейнер сдвигаем текст вниз */
    color: #025BAC;
}
  
  .product-card-list-line:nth-child(n+2) {
    margin-top: 5%;
  }

  
  .contact-section1 {
    width: 100%;
    height: 190px;
  }
  .contact-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 16px;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .contact-info-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 5%;
  }

  .contact-info-section-news {
    margin-left: 5%;
    margin-right: 0;
  }
  .contact-message {
    font: 700 32px/36px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-message1 {
    margin-top: 29px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-section-banner {
    box-sizing: border-box;
    display: block;
    max-width: initial;
    height: 61px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    .product-card-list-line {
      flex-direction: column;
    }

    .product-card-list-line a {
      width: 100%;
    }

    .product-card-row {
      width: 100%;
    }

    .news-card-link {
      width: 100%;
    }

    .product-card-list-line-entity-card h3 {
      font-size: 22px;
    }

   .product-card-list-line-entity-card h4 {
    font-size: 14px;
   }

   .product-card-list-line-entity:hover p {
    margin-top: 10%;
   }

   .product-card-list-line a:nth-child(n+2) {
    margin-top: 10%;
   }

   .contact-info-section-news {
    width: 60%;
   }

  
  }
