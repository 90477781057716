
.distributor-info-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.mega-heading {
    box-sizing: border-box;
    flex: 0 1 710px;
    max-width: 710px;
    font: 700 36px / 52px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
}

.distributor-info-section {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 579px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 579px;
    margin-left: 26px;
}

.company-description-text {
    flex: 0 0 auto;
    align-self: stretch;
    font: 400 14.5px / 20px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: right;
    white-space: pre-wrap;
}

.about_header {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  background-color: red;
  background-image: url(../assetsImage/assets/about_head.png);
  background-repeat:no-repeat;
  background-size:cover;
  background-position: 50% 62%;
  border-radius: 12px;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.about_header_head {
  width: 60%;
  margin-left: 2%;
  margin-top: 2%;

}

.about_header_head h2 {
  font-family: Inter;
  font-size: 28px;
  font-weight: bold;
  color:white;
  position: relative;
  z-index: 1;
  transition: opacity 0.4s ease;

}

.about_header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  
  width: 100%;
  height: 100%;
  background-color: rgba(4, 49, 94, 0.7); /* Начальный цвет затемнения */
  border-radius: 12px;
  opacity: 1; /* Устанавливаем прозрачность на 1 */
  transition: background-color 0.3s ease;
}



.about_header:hover::after {
  
  background-color: rgba(105, 107, 108, 0.7); /* Цвет затемнения при наведении */
  
}

.about_header:hover .about_header_head h2 {
  color: black; 
  transition: opacity 0.4s ease;

}

.about-content-status-ul {
  display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.about-requirements-status-list-partners {
  list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    height: 250px;
    width: 100%;
}

.about-requirements-status-list-partners:nth-child(n+2) {
  margin-top: 2%;
}

.about-requirements-status-list-partners li {
  display: flex;
    align-items: center;
    text-align: center;
    padding-left: 1%;
    padding-right: 1%;
    background-color: rgba(242, 246, 247, 1);
    border-radius: 12px;
    border: solid 0px #025BAC;
    transition: border-width 0.05s linear, text-align 0.1s ease-in-out, transform 0.3s ease-in-out;
    width: 100%;
    justify-content: center;
    font-size: 22px;
}

.about-requirements-status-list-partners li:hover {
  border-width: 0.5px;
    text-align: center;
    transform: translatey(2%);
    cursor: pointer;
}

.about-requirements-status-list-partners li:nth-child(n+2) {
  margin-left: 2%;
}

.super-brief-statement {
  font-weight: none;
  font: 24px/46px Inter, sans-serif;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  white-space: pre-wrap;
}
.cosmic-highlight {
  font: 700 32px/46px Inter, sans-serif;
  color: #025BAC;
  text-align: center;
}

.super_about_text {
  color: #025BAC;
  font-size: 34px;
  text-align: left;
  width: 100%;
}
.about_text {
  width: 100%;
  margin-bottom: 4%;
}

.about_blocks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.about_title h2 {
  font-size: 38px;
    width: 100%;
    margin-bottom: 5%;
    color: rgba(1, 80, 158, 1);
}

.about_block {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 0;
  margin-right: 0;
  position: relative;

}

.about_block_pic {
  width: 100%; /* Заполнить родительский блок полностью */
  transition: transform 0.3s ease; /* Плавное изменение масштаба */
}

.about_block:hover .about_block_pic {
  transform: scale(1.1); /* Увеличить размер изображения при наведении */
}

.about_block_pic {
  height: 60%;
  width: 100%;
  border-radius: 12px;
}

.about_block_text {
  width: 80%;
  margin-top: 3%;
  transition: margin-top 0.3s ease; /* Анимация изменения верхнего отступа текста */

}
.about_block_text p {
  font-size: 22px;
  font-family: Inter;
  text-align: center;
  
}

.about_block:hover .about_block_text {
  margin-top: 8%; /* При наведении на контейнер сдвигаем текст вниз */
}

.about_chronical {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto; /* Позволить блоку автоматически изменять высоту */
}

.about_chronical_block {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 200px; /* Фиксированная ширина блока */
  height: auto; /* Автоматическая высота */
  margin: 0 10px; /* Горизонтальный отступ */
  font-family: Inter;
  flex-shrink: 0; /* Запретить сжатие блоков */
}

.about_chronical_block:nth-child(n+2) {
  margin-left: 1%;
}

.about_chronical_block_pic {
  width: 100%;
  height: 200px; /* Фиксированная высота изображения */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;  
}

.about_chronical h2 {
  font-size: 40px;
  width: 100%;
  margin-bottom: 5%;
  color: rgba(1, 80, 158, 1);
}

.about_chronical_block_text h3 {
  margin-top: 5%;
  color: #025BAC;
  font-weight: bold;
  width: 100%;
  margin-left: 3%;
  margin-right: 3%;
}

.about_chronical_block_text {
  padding: 2px;
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: left;
  width: 100%; 
}

.about_chronical_block_text p {
  margin-top: 3%;
  margin-left: 3%;
  margin-right: 3%;
  text-align: left;
  width: 100%;
  white-space: normal; /* Позволяет тексту переноситься на новую строку */
  overflow: visible; /* Отключает обрезание текста */
  word-wrap: break-word; /* Разрешает разрывы слов, чтобы текст не выходил за границы */
}

.about_chronical_blocks {
  width: 100%;
  display: flex;
  flex-wrap: nowrap; /* Не переносить блоки на следующую строку */
  justify-content: flex-start;
  overflow-x: auto; /* Горизонтальная прокрутка */
  padding-bottom: 20px;
  scrollbar-width: none; /* Скрыть скролл */
}

.about_title_cert {
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 1; /* Начальная прозрачность */
  transition: opacity  0.5s ease; /* Плавный переход для прозрачности */
}

.about_title_cert img {
  display: none; /* Изображение по умолчанию скрыто */
  width: 26%;
  height: 100%;
  opacity: 1; /* Начальная прозрачность */
 
}

.about_header:hover + .about_title_cert img{
  display: flex; /* Изображение становится видимым при наведении на about_header */
  opacity: 1; /* Конечная прозрачность */
  
}

.about_title_cert:hover {
  opacity: 1; /* Конечная прозрачность */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.popup-visible {
  opacity: 1;
  visibility: visible;
}

.popup-hidden {
  opacity: 0;
  visibility: hidden;
}

.popup-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  max-width: 1000px;
  width: 80%;
  transform: translateY(-20px);
  transition: transform 0.3s ease;
  max-height: 80vh; /* ограничение по высоте */
  overflow-y: auto; /* добавляем прокрутку */
}

.popup-content p {
  font-size: 18px;
  text-align: left;
}

.popup-content p:nth-child(n+2) {
  margin-top: 1%;
}

.popup-visible .popup-content {
  transform: translateY(0);
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.about_text_a {
  text-decoration: none;
  color: black;
}
.about_text_a:hover {
  text-decoration: underline;
}





.about_cert_full {
  position: relative; 
  width: 100%;
    height: 70px;
    margin-left: 0;
    margin-right: 0;
}

.about_cert_full button {
  font: 20px Inter, sans-serif;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
    color: #025BAC;
    border: 1px solid #01509E;
    border-radius: 12px;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    width: 100%;
    transition-duration: 0.7s;
}

.about_cert_full button:hover {
  background-color: #025BAC;
    color: white;
    cursor: pointer;
}

.about_chronical_blocks::-webkit-scrollbar {
  display: none; /* Скрываем полосу прокрутки в Chrome и Safari */
}

@media (max-width: 768px) {
  .mega-heading {
    font: 700 20px / 18px Inter, sans-serif;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: auto;
    min-width: 50%;
    flex: 0;
    text-align: left;
  }

  .company-description-text {
    font: 500 16px / 12px Inter, sans-serif;
  }

  .distributor-info-section {
    max-width: 50%;
    margin-right: 0;
    margin-left: 0;
    flex: none;
    margin-top: 2%;
  }

  .company-description-text {
    text-align: right;
  }

  .distributor-info-block {
    display: none;
    flex-direction: row;
    width: 100%;
  }
  
  .about_header_head h2 {
    font-size: 20px;
  }

  .popup-content p {
    font-size: 14PX;
  }

  .popup-content {
    margin-top: 40%;
    height: 60%;
    
  }

  .about_title_cert img {
    width:90%;
  }

  .about_header_head {
    width: 95%;
  }

  .super-brief-statement {
    font: 16px / 30px Inter, sans-serif;
    
  }
  .about-requirements-status-list-partners {
    flex-direction: column;
    height: 350px;
  }

  .about-requirements-status-list-partners li {
    height: 60%;
    font-size: 16px;
  }
  .about_text {
    width: 100%;
  }


  .about-requirements-status-list-partners li:nth-child(n+2) {
    margin-left: 0;
    margin-top: 4%;
  }

  .super_about_text {
    font-size: 28px;
  
  }

  .popup-content {
    max-width: 250PX;
  }



  .cosmic-highlight {
    font: 700 20px / 30px Inter, sans-serif;
  }

  .about_title h2 {
    font-size: 22px;
    margin-bottom: 10%;
  }

  .about_title {
    width: 100%;
  }

  .about_block_text {
    height: 10%;
    width: 100%;
    word-break: break-all;
  }

  .about_block_text p {
    font-size: 18px;
  }

  .about_block_text p b {
    color: #025BAC;
  }

  .about_block_pic {
    height: 40%;
    width: 100%;
  }

  .about_chronical h2 {
    font-size: 24px;
    margin-bottom: 10%;
  }

  .about_blocks {
    flex-direction: column;
    align-items: center;
  }

  .about_block {
    width: 90%;
  }

  .about_block:nth-child(n+2) {
    margin-top: 10%;
  }
}