@font-face {
  font-family: 'Inter';
  font-weight: 400; /* Толщина шрифта: обычная */
  src: url('../../public/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype'); /* Путь к файлу шрифта TTF */
}

  html {
    min-width: 1510px;;
  }

  * {
    margin: 0 auto;
  }

  .container-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 70px;
    align-items: stretch;
    justify-content: flex-start;
    width: 1314px;
    background: rgba(255, 255, 255, 1);
    margin-top: 70px;
    flex: 1 0 auto;
  }


  .partner-info-section {
    display: flex;

    margin-top: 20px;
    width: 100%;
    height: 70px;
  }
  .partner-links-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    align-self: flex-end;
    justify-content: flex-start;
    padding-right: 49px;
    padding-left: 49px;
  }
  .product-button-style {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 182px;
    min-width: 182px;
    height: 49px;
    font: 600 16px Inter, sans-serif;
    color: rgba(2, 91, 172, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(2, 91, 172, 1);
    border-radius: 8px;
  }
  .industrial-product-categories-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
    
  }

  .industrial-text-block:hover {
  background-color: #025BAC; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 16px;
 }
  
  .industrial-text-snippet {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 275px;
    font: 400 16px/20px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .industry-description {
    font: 400 14px / 16px Inter, sans-serif;
    color: rgba(105, 107, 108, 1);
    flex: 0 0 auto;
    white-space: pre-wrap;
  }



  .commercial-transport-heading {
    flex: 0 0 auto;
    align-self: flex-start;
    padding-top: 136px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .column-layout-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .flex-container-with-text-aligned-to-end {
    margin-right: 0;
    display: flex;
  }
  .city-title-text-style {
    flex: 0 0 auto;
    margin-right: 2px;
    font: 300 14px/16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .svg-container {
    display: flex;
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    color: rgba(23, 33, 38, 1);
  }

  .company-info-logo {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0;
  }

  .logo_button_input {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
    gap: 50px;
  }



  .hierarchical-text-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3.5px;
  }

  .vertical-section-aside {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 161px;
  }
  .personal-dashboard-category {
    flex: 0 0 auto;
    align-self: stretch;
    margin-top: 96px;
    font: 400 16px/20px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .advertisement-container {
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    border-radius: 16px;
  }
  .ad-section-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 1314px;
    padding: 139px 48px 96px 95px;
    background: url("../assetsImage/assets/div__1o1t5gh_7b877d.png") no-repeat 50% / cover;
    border: none;
    border-radius: 16px;
  }
  .promo-header {
    margin: 0;
    flex: 0 0 auto;
    font: 700 69px/68px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .promotional-container {
    margin: 0;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 111px;
  }
  .image-container1 {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 49px;
  }
  .image-container-with-margin {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 48px;
    margin-left: 11px;
  }
  .hierarchical-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-content: flex-start;
  }
  .news-section-container1 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 300px;
    width: 100%;
    margin-top: 30px;
  }

  .news-item {
    width: 33%;
    margin-left: 0;
    margin-right: 0;
  }

  .news-item:nth-child(n+2) {
    margin-left: 2%;
  }

  .news-section-container1 a {
    text-decoration: none;
  }

  .news_1_news_2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;

  }

  .news_1_news_2 a {
    box-sizing: border-box;
    width: 100%;
    height: 47%;
    background-color: rgba(4, 76, 148, 1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
  }

  .news_4_news_5 {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 36px;
    justify-content: space-between;
  }

  .product-input-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
  .product-info-container {
    flex: 0 0 auto;
    width: undefined;
    margin-right: 12px;
    margin-left: 12px;
    border-top: 1px solid #90979c;
  }

  .product-input-container_search {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }

  .product-input-container1_search {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    width: 416px;
    padding-right: 12.5px;
    padding-left: 12.5px;
    margin-top: -1px;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 8px;
  }

  .product-input-container1 {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    width: px;
    padding-right: 12.5px;
    padding-left: 12.5px;
    margin-top: -1px;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 8px;
  }
  .svg-container1 {
    margin: 0;
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(23, 33, 38, 1);
  }
  .product-title-text-style {
    flex: 0 0 auto;
    margin-left: 8.5px;
    font: 400 16px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .news-heading {
    margin-left: 0;
    font: 700 32px/36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
  }

  .blue-gradient-box {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 1);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    overflow: hidden; /* Добавлено для предотвращения выхода текста за границы */
}

.blue-gradient-box-text {
    width: 100%;
    padding: 10% 0; /* Изначально текст сжимаем по высоте */
    height: 100%; /* Задаем высоту */
    transition: padding 0.3s ease, opacity 0.3s ease; /* Плавный переход */
}

.blue-gradient-box:hover .blue-gradient-box-text {
    padding: 15% 0; /* При наведении текст раздвигается */
}

.announcement-text-css,
.announcement-text {
    margin: 0;
    color: white;
    
}

.blue-gradient-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 76, 148, 1); 
  border-radius: 12px;
  
  transition: opacity 0.3s ease;
}

 /* .blue-gradient-box {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 1);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 12px;
    position: relative; 
    cursor: pointer;

  }

  .blue-gradient-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 1); 
    border-radius: 12px;
    
    transition: opacity 0.3s ease;
}

.blue-gradient-box:hover::after {
  opacity: 0; 
}

.announcement-text-css,
.announcement-text {
    position: relative;
    z-index: 1;
    color: white; 
}

.blue-gradient-box:hover .announcement-text,
.blue-gradient-box:hover .announcement-text-css {
    opacity: 0; 
    transition: opacity 0.3s ease;
}

  .tech-forum-container a {
    text-decoration: none;
  }

  .blue-gradient-box a {
    text-decoration: none;
  }

  .blue-gradient-box-text {
    width: 100%;
    padding-top: 10%;

  }*/
  .announcement-text-css {
    font: 600 22px/28px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    margin-left: 3%;
    margin-right: 3%;
  }
  .announcement-text {
    
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 5%;
    font: 400 15px/20px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
  }
  .tech-forum-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    padding: 36px 23px 28px;
    margin-top: 32px;
    background: url("../assetsImage/assets/div__pi5rd0_266eec.jpeg") no-repeat 50% / cover;
    background-color: #025BAC;
    border: none;
    border-radius: 16px;
  }
  .forum-title-text-style {
    flex: 0 0 auto;
    font: 400 23.5px/28px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .modern-tech-forum-description {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 155px;
    margin-top: 17px;
    font: 400 15.5px/20px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    white-space: pre-wrap;
    margin-left: 0;
  }
  .news-section-container2 {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 1);
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin-left: 2%;
    margin-right: 2%;
    transition: padding 0.3s ease, opacity 0.3s ease, transform 0.3s ease; 
  }

  .news-section-container2 a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  .news-section-container2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 76, 148, 1); 
    border-radius: 12px;
    transition: padding 0.3s ease, opacity 0.3s ease, transform 0.3s ease; 
   
}

.announcement-text-css,
.announcement-text {
    position: relative;
    z-index: 1;
    color: white; 
    transition: transform 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
}

.news-section-container2:hover .announcement-text,
.news-section-container2:hover .announcement-text-css {
  padding: 5% 0; 
  transform: translateY(0); 
}


.news-article-card {
    position: relative;
    z-index: 1;
    color: white; 
}

.news-section-container2:hover .news-article-card {
  padding: 5% 0; 
  transform: translateY(0); /* Поддержка плавного перемещения */
}

  .circular-button-style {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 186px;
    min-width: 186px;
    height: 44px;
    font: 400 15px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(196, 203, 209, 1);
    border-radius: 100px;
  }
  .rounded-button-style {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 147px;
    min-width: 147px;
    height: 44px;
    margin-left: 15px;
    font: 400 15px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(196, 203, 209, 1);
    border-radius: 100px;
  }
  .news-card {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
  }
  .news-article-card {
    box-sizing: border-box;
    width: 80%;
    font: 400 23.5px/28px Inter, sans-serif;
    margin-left: 2%;
    margin-top: 2%;
  }
  .news-section-container {
    box-sizing: border-box;
    flex: 0 0 417px;
    padding-top: 120px;
    margin-left: 31px;
  }
  .news-button-style {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    align-self: flex-end;
    width: 122px;
    min-width: 122px;
    height: 48px;
    font: 600 16px Inter, sans-serif;
    color: rgba(229, 1, 17, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(229, 1, 17, 1);
    border-radius: 8px;
  }
  .news-container {
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding: 43px 23px 110px;
    background: linear-gradient(109.87deg, rgba(226, 5, 17, 1) 15.18%, rgba(124, 3, 9, 1) 92.65%);
    border-radius: 16px;
  }
  .news-heading-style {
    font: 400 24px/28px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
  }
  .news-article-style {
    margin-top: 41px;
    font: 400 15px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
  }
  .gradient-news-container {
    box-sizing: border-box;
    width: 100%;
    padding: 42px 23px 116px;
    margin-top: 32px;
    background: linear-gradient(108.92deg, rgba(142, 160, 171, 1) 20.17%, rgba(142, 160, 171, 1) 43.83%, rgba(142, 160, 171, 1) 57.29%, rgba(57, 65, 69, 1) 92.64%);
    border-radius: 16px;
  }
  .news-heading-text-style {
    font: 400 23.5px/28px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .news-article-snippet {
    margin-top: 28px;
    font: 400 14.5px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .product-section {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-content: space-between;
    min-width: 1314px;
  }
  .product-catalog-layout {
    box-sizing: border-box;
    flex: 0 0 auto;
    min-width: 615px;
    margin: 0;
    width: 100%;
  }

  .products {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  }

  .upper_products {
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: space-between;
  }

  .down_products {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
  }

  .product-title {
    font: 700 32px/36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .vertical-card-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    margin-top: 36px;
  }
  .product-card-group {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 68px;
    align-items: flex-start;
    justify-content: space-between;
  }
  .motor-oil-container {
    box-sizing: border-box;
    flex: 1 1 0;
    padding: 25px 33px 9px;
    background: linear-gradient(306.67deg, rgba(14, 40, 114, 0.6) 2.1%, rgba(27, 76, 216, 0.6) 92.35%);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }

  .product_text{
    display: flex;
    flex-direction: column;
    height: 40%;
    justify-content: space-evenly;
  }

  .product_text p {
    text-align: left;
    margin: 0;
  }

  .product_text span a {
    text-decoration: none;
  }


  .motor-oil-image {
    box-sizing: border-box;
    display: block;
    width: 114px;
    max-width: initial;
    height: 112px;
    border: none;
    object-fit: cover;
    margin-left: 0;
  }
  .motor-oil-heading {
    margin-top: 19px;
    font: 700 18.5px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }

  .product_text span {
    margin-left: 0;
  }

  .motor-oil-details-button-text {
    width: 100%;
    font: 400 12px Inter, sans-serif;
    color: rgba(3, 19, 77, 1);
    white-space: pre-wrap;
    transition-duration: 0.4s;
  }

  .motor-oil-details-button-text:hover {
    color: #025BAC;
    font-weight: bold;
  }

  .motor-oil-details-button-text a {
    text-decoration: none;
    color: rgba(3, 19, 77, 1);
    cursor: pointer;
  }

  .tractor-oil-card {
    box-sizing: border-box;
    flex: 1 1 0;
    padding: 24px 33px 9px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .universal-tractor-oil-info {
    box-sizing: border-box;
    max-width: 196px;
    margin-top: 20px;
    font: 700 19px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .tractor-oil-details {
    margin-top: 5px;
    font: 400 12px Inter, sans-serif;
    color: rgba(2, 21, 83, 1);
    white-space: pre-wrap;
  }
  .industrial-product-card {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 67px;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
  }
  .industrial-product-section {
    flex: 1 1 0;
  }
  .industrial-product-details-container {
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    height: 230px;
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 16px;
    margin-left: 0;
    margin-right: 0;
    border: solid 0px #025BAC;
    transition: border-width 0.05s linear;
    
  }

  .industrial-product-details-container:hover {
    border-width: 2px;
  }

  .industrial-product-heading {
    box-sizing: border-box;
    width: 100%;
    margin-top: 19px;
    font: 700 19px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .industrial-products-link {
    margin-top: 10px;
    font: 400 12px Inter, sans-serif;
    color: rgba(1, 20, 78, 1);
    white-space: pre-wrap;
  }
  .flexible-lubricants-section {
    box-sizing: border-box;
    flex: 1 1 0;
    padding: 23px 33px 5px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .flexible-lubricants-section1 {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 4%;
    margin-left: 4%;
  }
  .plastic-lubricants-heading {
    margin-top: 16px;
    font: 700 19px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .detail-link {
    margin-top: 32px;
    font: 400 12px Inter, sans-serif;
    color: rgba(3, 23, 91, 1);
    white-space: pre-wrap;
  }
  .product-details-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 627px;
  }
  .product-card-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: space-between;
  }
  .product-card1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    min-width: 273px;
  }
  .transmission-oil-details-container {
    box-sizing: border-box;
    width: 100%;
    max-width: 273px;
    padding-top: 26px;
    padding-right: 24px;
    padding-left: 24px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .transmission-oil-title {
    box-sizing: border-box;
    width: 100%;
    margin-top: 18px;
    font: 700 19px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .transmission-details-btn {
    margin-top: 8px;
    font: 400 12px Inter, sans-serif;
    color: rgba(0, 20, 83, 1);
    white-space: pre-wrap;
  }
  .product-info-panel {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: 277px;
  }
  .primary-button {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    align-self: flex-end;
    width: 127px;
    min-width: 127px;
    height: 48px;
    font: 600 16px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(1, 80, 158, 1);
    border-radius: 8px;
  }
  .product-card {
    margin: 0;
    flex: 0 0 auto;
    padding-right: 4px;
    margin-top: 56px;
  }
  .blue-box-with-text {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 24px 9px 31px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .antifreeze-title {
    margin-top: 10px;
    font: 700 18.5px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .antifreeze-details-btn {
    margin-top: 27px;
    font: 400 12px Inter, sans-serif;
    color: rgba(0, 19, 79, 1);
    white-space: pre-wrap;
  }
  .product-card-grid {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 75px;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 621px;
    margin-top: 16px;
  }
  .corrosion-protection-card {
    box-sizing: border-box;
    flex: 1 1 0;
    padding: 26px 22px 6px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .product-description-text-style1 {
    box-sizing: border-box;
    max-width: 198px;
    margin-top: 17px;
    font: 700 18.5px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .product-details-button-text-color {
    margin-top: 9px;
    font: 400 12px Inter, sans-serif;
    color: rgba(1, 20, 79, 1);
    white-space: pre-wrap;
  }
  .thermal-products-section {
    box-sizing: border-box;
    width: 100%;
    max-width: 273px;
    padding: 27px 31px 6px;
    background: rgba(14, 40, 114, 0.2);
    border: 0.30000000000000004px solid rgba(0, 0, 0, 1);
    border-radius: 17px;
  }
  .product-title-text-style1 {
    box-sizing: border-box;
    width: 100%;
    margin-top: 12px;
    font: 700 19px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .thermal-processing-products-info {
    margin-top: 13px;
    font: 400 12px Inter, sans-serif;
    color: rgba(1, 21, 82, 1);
    white-space: pre-wrap;
  }

  .hero-image-container {
    box-sizing: border-box;
    display: block;
    width: 1314px;
    max-width: initial;
    height: 271px;
    border: none;
    border-radius: 16px;
    object-fit: cover;
  }
  .main-container1 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .product-details-section {
    flex: 1 0 auto;
    margin-right: 6px;
  }
  .flex-box-row-with-gap {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
  .recommended-section1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 306px;
  }
  .highlighted-text-block {
    font: 700 30.5px/36px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .recommended-section {
    box-sizing: border-box;
    width: 100%;
    height: 192px;
    margin-top: 32px;
    background: rgba(242, 246, 247, 1);
    border-radius: 8px;
  }
  .recommendation-text {
    margin-top: 40px;
    font: 400 15px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .centered-column-container {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 306px;
  }

  .priduct-section-title-katalog {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    justify-content: space-between;
  }

  .all_products {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .all_products button {
    width: 181px;
    height: 48px;
    background-color: white;
    color: #025BAC;
    border: 2px solid #025BAC;
    border-radius: 10px;
    transition-duration: 0.7s;
  }

  .all_products button:hover {
    background-color: #025BAC; /* Green */
    color: white;
    cursor: pointer;

  }

  .all_products button p {
    font-weight: bold;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
  }


  .card-container1 {
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 192px;
    background: rgba(242, 246, 247, 1);
    border-radius: 8px;
  }
  .content-box-text-style {
    flex: 0 0 auto;
    margin-top: 39px;
    font: 400 15px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .central-content-with-svg {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
  .nested-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .card-container {
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 305px;
    height: 192px;
    background: rgba(242, 246, 247, 1);
    border-radius: 8px;
  }
  .vertical-centered-flex-box {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-left: 30px;
  }
  .main-content-container1 {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .card-container-with-svg {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 12.5px;
    padding-left: 12.5px;
    margin-left: -25px;
    background: rgba(255, 255, 255, 1);
    border-radius: 24px;
    box-shadow: 0px 4px 4px 4px rgba(70, 119, 149, 0.08);
  }
  .svg-container2 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
  }
  .content-block-with-title {
    flex: 0 0 auto;
    margin-top: 16px;
    font: 400 15px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .nested-text-block {
    flex: 0 0 auto;
    margin-top: 4px;
    font: 400 15px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .product-description-section {
    height: 400px;
    box-sizing: border-box;
    width: 100%;
  }
  .product-card2 {
    background-image: url("../assetsImage/assets/white_sedan_driving_on_the_highway_accross_the_forest_01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .product-title-bold-white {
    font: 700 29.5px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
  }
  .product-description-text-style {
    font: 400 15.5px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
  }
  .product-button {
    box-sizing: border-box;
    display: block;
    width: 131px;
    min-width: 131px;
    height: 48px;
    margin-top: 37px;
    font: 600 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    cursor: pointer;
    background: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 8px;
  }
  .distributor-info-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mega-heading {
    box-sizing: border-box;
    flex: 0 1 710px;
    max-width: 710px;
    font: 700 47px/52px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .distributor-info-section {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 579px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 579px;
    margin-left: 26px;
  }
  .company-description-text {
    flex: 0 0 auto;
    align-self: stretch;
    font: 400 14.5px/20px Inter, sans-serif;
    color: rgba(88, 96, 102, 1);
    text-align: right;
    white-space: pre-wrap;
  }
  .company-info-button {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 227px;
    min-width: 227px;
    height: 49px;
    margin-top: 99px;
    font: 600 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(23, 33, 38, 1);
    border-radius: 8px;
  }
  .vertical-spacer {
    background-color: #F2F6F7;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .vertical-spacer iframe {
    margin: 0;
  }

  .cover-image-container {
    box-sizing: border-box;
    display: block;
    width: 1313px;
    max-width: initial;
    height: 741px;
    border: none;
    object-fit: cover;
  }
  .vertical-centered-column {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: stretch;
    align-self: center;
    justify-content: center;
    width: 74px;
  }

  .product-title-text-style-navigation {
    align-items: center;
    color: #90979c;
    display: flex;
    flex: 0 0 auto;
    font: 400 14.5px Inter, sans-serif;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    white-space: pre-wrap;
  }

  .product-title-text-style-navigation a {
    color: #90979c;
    text-decoration: none;
  }
  
  .product-section-with-images-navigation {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    margin-top: 3%;
    width: 100%;
  }

  .upper_products_mobile {
    display: none;
  }

  .upper_products_mobile:nth-child(n+2) {
    margin-top: 10%;
  }

  .product-section-with-images-navigation-upper {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .product-section-with-images-navigation-details {
    width: 100%;
    margin-top: 3%;
    align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;

  }

  


  @media (max-width: 768px) {
    html {
      min-width: auto;
    }
  
    .container-main {
      width: 100%;
      gap: 30px;
      margin-top: 40px;
    }
  
    .partner-links-container {
      gap: 20px;
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .product-button-style {
      width: 100%;
      min-width: auto;
    }
  
    .industrial-product-categories-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .commercial-transport-heading {
      padding-top: 50px;
      font-size: 14px;
    }
  
    .column-layout-container {
      margin: 10px 0;
    }
  
    .logo_button_input {
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
    }
  
    .company-info-logo {
      width: 100%;
    }
  
    .ad-section-container {
      width: 100%;
      padding: 60px 20px;
    }
  
    .promo-header {
      font-size: 40px;
    }
  
    .promotional-container {
      flex-direction: column;
      margin-top: 50px;
    }
  
    .image-container1, 
    .image-container-with-margin {
      width: 100%;
      height: auto;
    }
  
    .news-section-container1 {
      width: 100%;
      flex-direction: column;
      height: auto ;
      margin-top: 5%;
    }
    

    .announcement-text-css {
      font-size: 20px;
    }

    .announcement-text {
      font: 16px Inter, sans-serif;
    }

    .news_1_news_2 a:nth-child(n+2) {
      margin-top: 4%;
    }
  
    .news_1_news_2, 
    .news_4_news_5 {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }

    .news_1_news_2:nth-child(n+2) {
      margin-top: 3%;
    }

    .mega-heading {
      font: 34px Inter, sans-serif;
    }
  
    .product-input-container1_search {
      width: 100%;
    }
  
    .product-input-container1 {
      width: 100%;
    }
  
    .news-heading {
      font-size: 24px;
    }
  
    .blue-gradient-box-text {
      padding: 5% 0;
    }

    .news-item {
      width: 100%;
      height: 100%;
    }

    .news-item:nth-child(n+2) {
      margin-left: 0;
      margin-top: 2%;
    }

    .vertical-card-container {
     
      margin-top: 5%;
    }
  
    .product-card-group {
      flex-direction: column; /* Меняем направление на колонку */
      gap: 30px; /* Уменьшаем расстояние между элементами */
      align-items: center; /* Выравниваем по центру */
    }
  
    .motor-oil-container,
    .tractor-oil-card,
    .flexible-lubricants-section,
    .blue-box-with-text,
    .corrosion-protection-card {
      padding: 15px; /* Добавляем отступы для удобства на маленьких экранах */
      border-radius: 12px; /* Более мягкие углы */
    }
  
    .motor-oil-image {
      width: 300px; /* Уменьшаем размер изображений */
      height: 300px;
    }
  
    .industrial-product-details-container,
    .transmission-oil-details-container {
      padding: 10px;
    }
  
    .product-details-container {
      min-width: 100%; /* Карточки растягиваются на всю ширину */
      padding: 10px;
    }
  
    .hero-image-container {
      width: 100%;
      height: auto; /* Адаптируем изображение по ширине */
    }

    .industrial-product-details-container {
      max-width: 100%;
      width: 50%;
      height: 50%;
    }

    .product_text p {
      font-size: 38px;
      margin-top: 2%;

    }

    .motor-oil-details-button-text {
      font-size: 30px;
    }

    .motor-oil-details-button-text {
      font-size: 36px;
    }

    .upper_products {
      display: none;
    }

    .down_products {
      display: none;
    }

    .upper_products_mobile {
      display: flex;
      gap: 25px;
      width: 100%;
      height: 30%;
      justify-content: space-between;
    }

    .industrial-product-details-container_mobile {
      width: 50%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      align-items: center;
    }


    

    .motor-oil-image_mobile {
      width: 70%;
      height: 60%;
      margin-left: 0;
      margin-right: 0;
    }

    .product_text_mobile {
      display: flex;
    flex-direction: column;
    min-height: 40%;
    justify-content: space-evenly;
    margin-top: 10%;
    margin-left: 0;
    margin-right: 0;
    }

    .products {
      margin-top: 5%;
    }

    .product_text_mobile span a {
      text-decoration: none;
      color: #025BAC;
    font-weight: bold;
    }

    .product_text_mobile p {
      font: 700 16px Inter, sans-serif;
        margin-top: 2%;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
        word-break: break-all;
        
    }

    .motor-oil-heading_mobile {
      
    }

    .motor-oil-details-button-text_mobile {
        font-size: 14px;
        margin-top: 2%;
        font-weight: none;
    }


    .product_text_mobile span {
      margin-left: 0;
      font-size: 14px;
    }

    .motor-oil-heading_mobile {
      color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    font: 700 44px Inter, sans-serif;
        color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    }

    .priduct-section-title-katalog {
      margin-top: 3%;
    }

    .news-section-container2 {
      display: none;
    }
    
    .hierarchical-container {
      height: auto;
    }

    .vertical-spacer iframe {
      width: 100%;
      height: 100%;
      border: none;
    }

    

    .product-section-with-images-navigation-upper {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      display: flex;
    flex-wrap: wrap; /* Позволяет переносить элементы на новую строку */
    max-width: 100%; /* Ограничение ширины */
    }

    .product-section-with-images-navigation-details {
      width: 100%;
      margin-top: 10%;
      align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    height: auto;

    }

    

    .product-section-with-images-navigation {
      margin-top: 10%;
      align-items: center;
      word-wrap: break-word;
      overflow-wrap: break-word;
      display: flex;
      flex-wrap: wrap; /* Позволяет переносить элементы на новую строку */
      max-width: 100%; /* Ограничение ширины */
    }

    .product-title-text-style-navigation {
      word-wrap: break-word; /* Принудительно переносит длинные слова */
      overflow-wrap: break-word; /* Переносит текст на следующую строку */
      white-space: normal;
    }

    .product-title-text-style-navigation a {
      
      word-wrap: break-word; /* Принудительно переносит длинные слова */
      overflow-wrap: break-word; /* Переносит текст на следующую строку */
      white-space: normal;
    }

    

    .vertical-spacer {
      height: 1000px;
    }

    .product-catalog-layout {
      min-width: auto;
    }

    

    
  }
  

 