.content-status-section h3 {
    width: 100%;
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 2%;
    text-align:justify;
}

.requirements-status-list {
    list-style-type: none;
    padding: 0;
   
}

.requirements-status-list li {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 1.5%;
    text-align: center;
    background-color: rgba(242, 246, 247, 1);
    border-radius: 12px;
    border: solid 0px #025BAC;
    transition: border-width 0.05s linear, 
    text-align 0.1s ease-in-out,
    font-weight 0.05s ease-in-out,
    transform 0.3s ease-in-out;
    
}

.requirements-status-list li p {
    font-weight: 100;
}

.requirements-status-list li:hover {
    border-width: 1px;
    text-align: center;
    transform: translatey(2%);
    cursor: pointer;
}

.requirements-status-list li:nth-child(n+2) {
    margin-top: 10px;
}

.content-status:nth-child(n+2) {
    margin-top: 3%;
}

.requirements-status-list_list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between; 
    flex-direction: row;
}

.requirements-status-list_list li {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px; 
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: rgba(242, 246, 247, 1);
    border-radius: 12px;
    border: solid 0px #000000;
    transition: border-width 0.05s linear, 
    text-align 0.1s ease-in-out,
    font-weight 0.05s ease-in-out,
    transform 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.requirements-status-list_list li:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #025BAC;
}

.requirements-status-list_list li:hover p {
    visibility: visible;
    opacity: 1;
    height: auto;
    margin-top: 10px;
    color: white
}

.requirements-status-list_list li p {
    font-weight: 100;
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
    transition: visibility 0s, 
                opacity 0.4s ease-in-out, 
                height 0.4s ease-in-out;
}

.requirements-status-list_list li::first-line {
    font-weight: bold;
}

.requirements-status-list_list li:nth-child(n+2) {
    margin-left: 10px;
}

.requirements-status-list_list li h4 {
    margin: 0;
    font-weight: bold;
    transition: opacity 0s ease-in-out;
}

.requirements-status-list_list li:hover h4 {
    opacity: 0; 
}

.requirements-status-list_list li:hover p {
    opacity: 1; /* Показать текст описания при наведении */
    transform: translate(0%, -10%);
}

@media (max-width: 768px) {
    .content-status-section h3 {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .requirements-status-list li {
        font-size: 14px;
    }

    .content-status:nth-child(n+2) {
        margin-top: 5%;
    }

    .requirements-status-list_list {
        flex-direction: column;
    }

    .requirements-status-list_list li:nth-child(n+2) {
        margin-left: 0;
        margin-top: 5%;
    }

    .requirements-status-list_list li p {
        font-size: 16px;
    }

}