@font-face {
  font-family: 'Inter';
  font-weight: 700; /* Толщина шрифта: обычная */
  src: url('../../public/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype'); /* Путь к файлу шрифта TTF */
}

html {
    min-width: 1510px;
    font-family: Inter;
  }



  * {
    margin: 0 auto;
  }

  #root {
    width: 100%;
  }

  .container-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 70px;
    align-items: stretch;
    justify-content: flex-start;
    width: 1314px;
    background: rgba(255, 255, 255, 1);
    margin-top: 30px;
  }
  .company-info-layout {
    display: flex;
    justify-content: center;
    max-width: 1314px;
    flex-direction: column;
    margin-top: 30px;
  }

  .company-info-layout img {
    max-height: 400px;
    margin-left: 0;
    margin-right: 0;
  }

  .corporate-info-container1 {
    width: 100%;
    flex: 0 0 auto;
  }


  #header_image {
    transition: opacity 0.5s ease-in-out;
    object-fit: cover;
    position: center;
    }

    #hover-text {
      transition: opacity 0.5s ease-in-out;
    object-fit: cover;
    position: center;
    }

  .company-info-layout img {
    max-width: 1314px;
    border-radius: 16px 16px 16px 0;
    
  }


  .corporate-section {
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 1);
  }
  .company-info-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: rgba(242, 246, 247, 1);
  }
  /*.header-navigation-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 58px;
    align-items: center;
    justify-content: flex-start;
    max-width: 1314px;
    padding-top: 19.5px;
    padding-bottom: 10px;
  }*/
  .corporate-info-container {
    width: 100%;;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .vertical-section-with-products {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo_button {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
  }
  
  .button_product {
    margin-left: 30px;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .button_product button {
    width: 181px;
    height: 48px;
    background-color: #F2F6F7;
    color: #025BAC;
    border: 2px solid #025BAC;
    border-radius: 10px;
    transition-duration: 0.7s;
  }

  .button_product button:hover {
    background-color: #025BAC; /* Green */
    color: white;
    cursor: pointer;
  }

  .button_product button p {
    font-weight: bold;
    font-size: 16px;
    }


  .company-news-section {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }
  .company-info-styles {
    flex: 0 0 auto;
    font: 400 14px/16px Inter, sans-serif;
    color: rgba(105, 107, 108, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }

  .company-info-styles a {
    text-decoration: none;
    color: rgba(105, 107, 108, 1);
    transition-duration: 0.7s;
  }

  .company-info-styles a:hover {
    text-decoration: none;
    color: #424344;
  }

  .banner-image {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    max-width: initial;
    height: 60px;
    border: none;
    object-fit: cover;
    margin-left: 0;

  }
  .metal-industry-section {
    flex: 0 0 auto;
    padding-right: 21px;
    padding-left: 21px;
    margin-top: -5px;
  }
  .industrial-text-block {
    display: flex;
    box-sizing: border-box;
    font: 400 16px/20px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    margin: 0;
    transition-duration: 0.7s;
    height: 100%;
    align-items: center;
  }

  .search {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;
    border-radius: 12px;
  }

  .field::placeholder {
    color: rgba(105, 107, 108, 1); 
    font: 400 14px Inter, sans-serif;
  
  }
  .field input {
    font-family: Inter, sans-serif;
  }
  
  .field:focus {
    outline: 0px;
  }


  .field {
    width: 270px;
    height: 42px;
    padding-left: 15px;
    border: 0px solid #324b4e;
    background: #ffffff;
    outline: none;
    position: relative;
    transition: 0.5s linear;
    border-radius: 5px;
    font-family: Inter;
    font-size: 16px;
  }

  .field:focus {
    outline: none;
    box-shadow: 0.5px 0.5px 0.5px #f4f7f6;
    padding-right: 50px;
    transition-duration: 0.5s;
  }


  .search_button {
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-image: url(../assetsImage/assets/search-svgrepo-com.svg);
    background-color: #176bb9;
    background-position: center;
    background-repeat: no-repeat;
    background-size:60%;
    
  }

  .industrial-text-block p {
    text-align: center;
    width: 100%;
  }

  .industrial-text-block p a {
    color:rgba(23, 33, 38, 1);
    text-decoration: none;
    transition-duration: 0.7s;
  }

  .industrial-text-block p a:hover {
    color: white;
  }

  .partner-info-section {
    display: flex;

    margin-top: 20px;
    width: 100%;
    height: 70px;
  }
  .partner-links-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    align-self: flex-end;
    justify-content: flex-start;
    padding-right: 49px;
    padding-left: 49px;
  }
  .product-button-style {
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    width: 182px;
    min-width: 182px;
    height: 49px;
    font: 600 16px Inter, sans-serif;
    color: rgba(2, 91, 172, 1);
    cursor: pointer;
    background: transparent;
    border: 1px solid rgba(2, 91, 172, 1);
    border-radius: 8px;
  }
  .industrial-product-categories-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
    
  }

  .industrial-text-block:hover {
  background-color: #025BAC; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 16px;
 }
  
  .industrial-text-snippet {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 275px;
    font: 400 16px/20px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .industry-description {
    font: 400 14px / 16px Inter, sans-serif;
    color: rgba(105, 107, 108, 1);
    flex: 0 0 auto;
    white-space: pre-wrap;
  }

  .industry-description a {
    text-decoration: none;
    color: rgba(105, 107, 108, 1);
    transition-duration: 0.1s;
  }

  .industry-description a:hover {
    font-weight: bold;
    color: #025BAC;
  }


  .commercial-transport-heading {
    flex: 0 0 auto;
    align-self: flex-start;
    padding-top: 136px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .column-layout-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .flex-container-with-text-aligned-to-end {
    margin-right: 0;
    display: flex;
  }
  .city-title-text-style {
    flex: 0 0 auto;
    margin-right: 2px;
    font: 300 14px/16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .svg-container {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(23, 33, 38, 1);
    margin-left: 0;
    margin-right: 0;
  }

  .company-info-logo {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0;
  }

  .logo_button_input {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
    gap: 50px;
  }



  .hierarchical-text-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3.5px;
  }

  .vertical-section-aside {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 161px;
  }
  .personal-dashboard-category {
    flex: 0 0 auto;
    align-self: stretch;
    margin-top: 96px;
    font: 400 16px/20px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .advertisement-container {
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    border-radius: 16px;
  }
  .ad-section-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 1314px;
    padding: 139px 48px 96px 95px;
    background: url("../assetsImage/assets/div__1o1t5gh_7b877d.png") no-repeat 50% / cover;
    border: none;
    border-radius: 16px;
  }
  .promo-header {
    margin: 0;
    flex: 0 0 auto;
    font: 700 69px/68px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    white-space: pre-wrap;
  }
  .promotional-container {
    margin: 0;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 111px;
  }
  .image-container1 {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 49px;
  }
  .image-container-with-margin {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 48px;
    margin-left: 11px;
  }

  .hover-text {
    display: none;
    font-family: Inter;
    /*display: flex;*/
    align-items: center;
    margin-left: 0;
    font-size: 24px;
    font-weight: bold;
    opacity: 1;
    color: white;
    padding: 20px;
    background-color: rgba(1, 80, 158, 0.75);
    border-radius: 0 0 12px 12px; /* Скруглить только нижние углы */
    min-width: 150px;
    height: 20px;
    max-width: 700px;
    transition: opacity 0.5s ease;
  }
  .hierarchical-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
  }

  @media (max-width: 768px) {

    .corporate-info-container1 {
      padding: 10px;
      flex-direction: column;
      align-items: center;
    }
  
    .company-info-container {
      flex-direction: column;
    }
  
    .company-news-section {
      display: none; /* скрываем текстовые ссылки на малых экранах */
    }
  
    .logo_button_input {
      flex-direction: column;
      align-items: center;
    }
  
    .logo_button {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  
    .button_product button {
      width: 100%;
      margin-top: 10px;
    }
  
    .product-input-container_search {
      width: 100%;
    }
  
    .banner-image {
      max-width: 100%;
      height: auto;
    }
  
    .partner-info-section {
      margin-top: 20px;
    }
  
    .industrial-product-categories-container {
      flex-direction: column;
      align-items: center;
    }
  
    .industrial-text-block {
      width: 100%;
      text-align: center;
      padding: 15px;
      margin-bottom: 10px;
    }
  
    #header_image {
      width: 100%;
      height: auto;
    }
  
    .menu-toggle {
      display: block;
      font-size: 24px;
    }
  
    .menu {
      display: none;
    }
  
    .menu.open {
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      background-color: white;
      padding: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
  
    .company-info {
      flex-direction: column;
      align-items: center;
    }
  
    .company-info-styles {
      margin: 10px 0;
      font-size: 18px;
    }

    .company-info-layout {
      display: none;
    }
    .header-navigation-bar {
      width: 100%;
    }
    .partner-info-section {
      display: none;
    }

    .vertical-section-with-products {
      width: 100%;
    }
    
  }