.catalog-container-solution {
    width: 100%;
}

.fullwidth-container-solution {
    margin-top: 3%;
    height: 200px;
    width: 100%;
    background: rgba(242, 246, 247, 1);
    border-radius: 12px;
    border: solid 0px #025BAC;
    transition: border-width 0.05s linear;
}

.fullwidth-container-solution:hover {
    border-width: 2px;
}

.fullwidth-container-solution a {
    height: 100%;
    text-decoration: none;
}


.product-card-list-solution {
    display: flex;
    flex-direction: column;
}

.vehicle-info-card-solution {
    height: 100%;
}

.transport-card-solution {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.vehicle-image-container {
    height: 100%;
    width: 25%;
    margin-left: 0;
    margin-right: 0;

}

.vehicle-image-container img {
    height: 80%;
    width: 70%;
    margin-left: 10%;
    margin-top: 6%;
    border-radius: 0px;
}

.transport-info-card-solution {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.transport-info-card-solution p {
    margin-left: 0%;
}

@media (max-width: 768px) {
    .vehicle-image-container {
        height: 50%;
        max-width: 50%;
        margin-left: 2%;
        width: 40%;
    }

    .vehicle-image-container img {
        width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-left: 0;
    margin-top: 0;
    }

    .transport-info-card-solution {
        width: 60%;
        margin-left: 5%;
        word-break: break-all;
        padding-right: 1%;
    }

    .transport-card-solution {
        box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: rgba(242, 246, 247, 1);
    border-radius: 12px;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    }

}