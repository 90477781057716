


  .fullwidth-container-legkovie {
    width: 25%;
    
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    
  }

  .fullwidth-container-legkovie a {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .fullwidth-container-legkovie:nth-child(n+2) {
    margin-left: 2%;
  }

  .fullwidth-container-upp{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .fullwidth-container-upp:nth-child(n+2) {
    margin-top: 5%;
  }
  .product-section-with-images {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .svg-container {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(23, 33, 38, 1);
    margin-left: 0;
    margin-right: 0;
  }

  .svg-container a img {
    width: 24px;
    height: 24px;

  }

  .svg-container1 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-left: 0;
    margin-right: 0;
  }

  .product-search-container {
    box-sizing: border-box;
    width: 100%;
    margin-top: 24px;
  }
  .inner-content-wrapper {
    padding-right: 13px;
    padding-left: 13px;
  }
  .fullwidth-border-top-box {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #90979c;
  }
  .negative-margin-top {
    margin-top: -18px;
  }
  .image-container {
    box-sizing: border-box;
    display: block;
    width: 538px;
    max-width: initial;
    height: 49px;
  }
  .product-search-prompt {
    padding-right: 48px;
    padding-left: 49px;
    margin-top: -8px;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .product-description-text-style {
    font: 400 20px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    text-align: left;
  }
  .highlighted-text {
    font: 400 20px Inter, sans-serif;
    color: rgba(244, 125, 50, 1);
    text-align: left;
  }
  .product-catalog-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 110px;
  }
  .catalog-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .catalog-title {
    font: 700 40px/36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .product-card-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 47px;
  }

  .vehicle-info-card-legkovie {
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    
    width: 100%;
  }

  .fullwidth-container-legkovie a {
    width: 100%;
    text-decoration: none;
    border: solid 0px #025BAC;
    border-radius: 12px;
    transition: border-width 0.05s linear;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .fullwidth-container-legkovie a:hover {
    border-width: 2px;
  }


  .text_details {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 8%;
    align-items: left;
  }

  .trandsport-text {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3%;
    color: rgba(1, 80, 158, 1);
    word-wrap: break-word;
    font-size: 18px;
    text-align: center;
  }




  .transport-info-card-legkovie {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: center;
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
    margin-right: 0;
    
  }
  .transport-heading-style-legkovie {
    flex: 0 0 auto;
    font: 600 24px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .transport-details-button-text-legkovie {
    font: 400 20px/16px Inter, sans-serif;
    color: rgb(175, 215, 255);
    white-space: pre-wrap;
    margin-left: 0;
    transition-duration: 0.1s;
  }

  .transport-details-button-text-legkovie:hover {
    color: #025BAC;
    font-weight: bold;


  }

  .vehicle-image-container-legkovie {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .vehicle-image-container-legkovie img {
    width: 70%;
    height: 100%;
    border-radius: 12px;
    margin-left: 0;
    margin-right: 0;
  }


  .horizontal-rule-display-style {
    box-sizing: border-box;
    display: block;
    width: 642px;
    max-width: initial;
    height: 2px;
  }
  .transport-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #c4cbd1;
  }
  .industrial-lubricants-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 31px;
  }
  .industrial-lubricant-image {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 161px;
    border: none;
    border-radius: 5px;
    object-fit: cover;
  }
  .hero-banner {
    box-sizing: border-box;
    flex: 0 0 310px;
    max-width: initial;
    height: 581px;
    padding: 25px 29px 488px;
    margin-left: 31px;
    font: 700 40px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
    background: url(../assetsImage/assets_catalog/p_41_430_634006.png) no-repeat 50% / cover;
    border: none;
  }
  .contact-section1 {
    width: 100%;
    height: 190px;
  }
  .contact-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 16px;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .contact-info-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 5%;
  }

  .contact-info-section {
    margin-left: 5%;
    margin-right: 0;
  }
  .contact-message {
    font: 700 32px/36px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-message1 {
    margin-top: 29px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-section-banner {
    box-sizing: border-box;
    display: block;
    max-width: initial;
    height: 61px;
    margin-left: 0;
    margin-right: 0;
  }

  .catalog-container_mobile {
    display: none;
    width: 100%;
  }

  .catalog-title_mobile {
    display: none;
  }

  .fullwidth-container-upp_mobile {
    display: none;
    width: 100%;
  }

  .fullwidth-container-legkoviev_mobile {
    display: none;
    width: 100%;

  }

  .fullwidth-container-legkoviev_mobile:nth-child(n+2) {
    margin-left: 5%;
  }

  .fullwidth-container-legkoviev_mobile a {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    text-decoration: none;
  }

  .vehicle-info-card-legkovie_mobile {
    display: none;
  }

  .vehicle-image-container-legkovie_mobile {
    display: none;
  }

  .vehicle-image-container-legkovie_mobile img {
    width: 100%;
    height: 100%;
  }

  .transport-info-card-legkovie_mobile {
    display: none;
  }

  .text_details_mobile {
    display: none;
  }

  .trandsport-text_mobile {
    display: none;
    color: rgba(1, 80, 158, 1);
    text-align: center;
  }

  .transport-section_mobile {
    display: none;
  }

  .catalog-container_desktop {
    width: 100%;
  }

  .fullwidth-container-upp-mobile {
    display: none;
  }

  @media (max-width: 768px) {

    .catalog-container {
      display: none;
    }

    .fullwidth-container-upp-mobile {
      display: flex;
    }

    .fullwidth-container-upp-mobile {
      display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    }

    .fullwidth-container-legkovie-mobile {
      width: 25%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    }

    .fullwidth-container-legkovie-mobile a {
      width: 100%;
    text-decoration: none;
    border: solid 0px #025BAC;
    border-radius: 12px;
    transition: border-width 0.05s linear;
    padding-top: 2%;
    padding-bottom: 2%; 
    }

    .vehicle-info-card-legkovie-mobile {
      align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    }

    .transport-info-card-legkovie-mobile {
      display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: center;
    justify-content: center;
    margin-right: 0;
    }

    .vehicle-image-container-legkovie-mobile {
      width: 100%;
    display: flex;
    }

    .vehicle-image-container-legkovie-mobile img {
      width: 70%;
    height: 50%;
    border-radius: 12px; 
    }

    .text_details-mobile {
      display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-top: 8%;
    align-items: center;
    }

    .trandsport-text-mobile {
      margin-left: 0;
    margin-right: 0;
    margin-bottom: 3%;
    color: rgba(1, 80, 158, 1);
    word-wrap: break-word;
    font-size: 18px;
    text-align: center;
    }

    .trandsport-text-mobile {
      box-sizing: border-box;
    width: 100%;
    margin-top: 1px;
    border-top: 1px solid #c4cbd1;
    }

    .catalog-container_desktop {
      margin-left: 0;
      margin-bottom: 0px;
    }
  
    .catalog-title {
      margin-left: 0;
    }
  
    .fullwidth-container-upp {
      display: none;
    }
  
    .fullwidth-container-legkoviev {
      display: none;
    }
  
    .vehicle-info-card-legkovie {
      display: none;
    }
  
    .vehicle-image-container-legkovie {
      display: none;
    }
  
    .transport-info-card-legkovie {
      display: none;
    }
  
    .text_details {
      display: none;
    }
  
    .trandsport-text {
      display: none;
    }
  
    .transport-section {
      display: none;
    }




    .catalog-container_mobile {
      display: flex;
      flex-direction: column;
    }
  
    .catalog-title_mobile {
      display: flex;
      margin-left: 0;
    }
  
    .fullwidth-container-upp_mobile {
      display: flex;
      flex-direction: row;
    }

    .fullwidth-container-upp_mobile:nth-child(n+2) {
      margin-top: 8%;
    }
  
    .fullwidth-container-legkoviev_mobile {
      display: flex;
      width: 50%;
      margin-left: 0;
      margin-right: 0;
    }

    .fullwidth-container-legkoviev_mobile a {
      width: 100%;
    }
  
    .vehicle-info-card-legkovie_mobile {
      display: flex;
      width: 70%;
    }
  
    .vehicle-image-container-legkovie_mobile {
      display: flex;
    }
  
    .transport-info-card-legkovie_mobile {
      display: flex;
      margin-top: 5%;
    }
  
    .text_details_mobile {
      display: flex;
    }
  
    .trandsport-text_mobile {
      display: flex;
      font-size: 16px;
    }
  
    .transport-section_mobile {
      display: flex;
    }

    .product-section-with-images {
      align-items: center;
      margin-top: 5%;
      display: flex;
    flex-wrap: wrap; /* Позволяет переносить элементы на новую строку */
    max-width: 100%; /* Ограничение ширины */
    }

    .transport-section_mobile {
      box-sizing: border-box;
      width: 100%;
      margin-top: 5%;
      border-top: 1px solid #c4cbd1;
    }

    
  }

