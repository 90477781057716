main {
  font-family: Inter;
}
  
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .home-icon {
    width: 24px;
    height: 24px;
  }
  
  .breadcrumb-icon {
    width: 24px;
    height: 24px;
    margin: 0 10px;
  }
  
  .breadcrumb-link {
    color: #000;
    text-decoration: none;
    font-size: 14px;
  }
  
  .product-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
 
  
  .product-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .product-image-container {
    height: 250px;
    border-radius: 12px;
    margin-left: 0%;
    margin-right: 5%;
  }

  .product-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px;

  }

  .products_catalog_tovar {
    width: 100% ;
    display: flex;
    flex-direction: row;
  }

  .product-title-tovar {
    font: 700 40px / 36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5%;
  }

  .product-info {
    width: 100%;
  }
  


  
  .product-price {
    font-size: 28px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  
  .product-description {
    font-size: 18px;
    text-align: justify;
    color: #5F5A5A;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 0;
    width: 80%;
    margin-right: 0;
  }

  .product_opisanie {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .product-specifications {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-left: 0;
    margin-right: 0;
    

  }

  .product-specifications h3 {
    font-weight:600;
    font-size:24px;
    color: #01509E;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;

  }
  
  .product-specifications ul {
    margin-top: 10%;
    margin-left: 0;
    padding: 0;
    font-size:18px;
    color: #5F5A5A;
    list-style: none;
    text-align: center;
  }
  
  .product-specifications li {
    margin-bottom: 6%;
    text-align: left;
  }
  
  .product-actions {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .info-box-block-tovar {
    width: 28%;
    height:70px;
    margin-left: 0;
    margin-right: 0;
  }

  .info-box-block-tovar button {
    font: 20px Inter, sans-serif;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
    color: #025BAC;
    border: 3px solid #01509E;
    border-radius: 12px;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    width: 100%;
    transition-duration: 0.7s;
  }

  .info-box-block-tovar button:hover {
    background-color: #025BAC;
    color: white;
    cursor: pointer;
  }
  
  .product-button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .product-button:hover {
    background-color: #0056b3;
  }

  .contact-section-tovar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 190px;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 16px;
    margin-top: 7%;
  }

  .contacts-tovar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .contact-info-section-svyaz-tovar {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 0;
  }

  .contact-info-section-svyaz-tovar h2 {
    font: 700 32px / 36px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    width: 100%;
  }

  .contact-info-section-svyaz-tovar p {
    margin-top: 29px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }

  .contact-info-logos-tovar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 5%;
  }

  .transport-section-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 2%;
    border-top: 1px solid #c4cbd1;
  }

  .product-specifications h3:focus {
    transition-duration: 0.6s;
  }

  .collapsible-content {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
  }
  
  .collapsible-content.active {
    height: auto;
    opacity: 1;
    transition: height 0.5s ease-in, opacity 0.5s ease-in;
    width: 100%;
    margin-left: 1%;
    overflow: auto;
    
  }

  .arrow {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s ease;
  }

  .product_opisanie_mobile {
    display: none;
  }
  
  .arrow.open {
    transform: rotate(180deg); /* Поворот стрелки вниз при раскрытии */
  }

  @media (max-width: 768px) {
    .product-title-tovar {
      font: 700 24px / 26px Inter, sans-serif;
      margin-bottom: 7%;
    }

    .product-specifications ul {
      margin-top: 6%;
      text-align: left;
    }

    .product-actions {
      flex-direction: column;
      align-items: center;
    }

    .info-box-block-tovar {
      width: 70%;
      height: 55px;

    }

    .info-box-block-tovar:nth-child(n+2) {
      margin-top: 7%;
    }

    .product-actions {
      background: rgba(242, 246, 247, 1);
      padding-top: 5%;
      padding-bottom: 5%;
      border-radius: 12px;
    }

    .info-box-block-tovar button {
      font: 18px Inter, sans-serif;
      font-weight: bold;
    }

    .transport-section-section {
      margin-top: 5%;
    }

    .product-image-container {
      width: 60%;
      height: auto;
    }

    .product-details {
      width: 100%;
      margin-top: 3%;
    }


    .product-description {
      font-size: 18px;
    }

    .product-price {
      display: none;
    }

    .product_opisanie_mobile {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .products_catalog_tovar {
      height: auto;
      flex-direction: column;
    }

    .product-specifications {
      align-items: flex-start;
      width: 100%;
    }

    .product-specifications:nth-child(n+2) {
      margin-top: 7%;
    }

    .product_opisanie {
      display: none;
    }

    .product-specifications h3 {
      font-size: 18px;
      
    }
  }