
  .fullwidth-container-industrial {
    width: 47%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
  .product-section-with-images {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .svg-container {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    color: rgba(23, 33, 38, 1);
    margin-left: 0;
    margin-right: 0;
  }

  .svg-container a img {
    width: 24px;
    height: 24px;

  }

  .svg-container1 {
    display: flex;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-left: 0;
    margin-right: 0;
  }

  .product-search-container {
    box-sizing: border-box;
    width: 100%;
    margin-top: 24px;
  }
  .inner-content-wrapper {
    padding-right: 13px;
    padding-left: 13px;
  }
  .fullwidth-border-top-box {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #90979c;
  }
  .negative-margin-top {
    margin-top: -18px;
  }
  .image-container {
    box-sizing: border-box;
    display: block;
    width: 538px;
    max-width: initial;
    height: 49px;
  }
  .product-search-prompt {
    padding-right: 48px;
    padding-left: 49px;
    margin-top: -8px;
    color: rgba(88, 96, 102, 1);
    white-space: pre-wrap;
  }
  .product-description-text-style {
    font: 400 20px Inter, sans-serif;
    color: rgba(144, 151, 156, 1);
    text-align: left;
  }
  .highlighted-text {
    font: 400 20px Inter, sans-serif;
    color: rgba(244, 125, 50, 1);
    text-align: left;
  }
  .product-catalog-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 110px;
  }
  .catalog-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .catalog-title {
    font: 700 40px/36px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .product-card-list-industrial {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0px;
    justify-content: space-between;
  }

  .products-cards-lists {
    display: flex;
    flex-direction: column;
    margin-top: 42px;
    width: 100%;
  }
  .vehicle-info-card-industrial {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 200px;
    margin-left: 0;
    margin-right: 0;

  }

  .fullwidth-container-industrial a {
    width: 100%;
    text-decoration: none;
    border: solid 0px #025BAC;
    border-radius: 12px;

    transition: border-width 0.05s linear;
  }

  .fullwidth-container-industrial a:hover {
    border-width: 2px;
  }

  .transport-card-industrial {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: rgba(242, 246, 247, 1);
    border-radius: 12px;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
  }
  .vehicle-image-container-industrial {
    max-width: initial;
    height: 110px;
    border: none;
    border-radius: 5px;
    object-fit: contain;
    margin-left: 5%;
    margin-right: 0;
  }

  .vehicle-image-container-industrial img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .transport-info-card-industrial {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: center;
    justify-content: center;
    margin-left: 4%;
    margin-right: 0;
    width: 60%;
  }
  .transport-heading-style-industrial {
    flex: 0 0 auto;
    font: 600 24px/24px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
    width: 100%;
  }
  .transport-details-button-text-industrial {
    margin-top: 34px;
    font: 400 16px/16px Inter, sans-serif;
    color: rgba(1, 80, 158, 1);
    white-space: pre-wrap;
    margin-left: 0;
    transition-duration: 0.05s;
  }

  .transport-details-button-text-industrial:hover {
    color: #025BAC;
    font-weight: bold;


  }

  .transport-card-industrial1 {
    flex: 0 0 auto;
    padding-right: 234px;
    padding-left: 234px;
    margin-top: 5px;
  }
  .horizontal-rule-display-style {
    box-sizing: border-box;
    display: block;
    width: 642px;
    max-width: initial;
    height: 2px;
  }
  .transport-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #c4cbd1;
  }
  .industrial-lubricants-section {
    box-sizing: border-box;
    width: 100%;
    margin-top: 31px;
  }
  .industrial-lubricant-image {
    box-sizing: border-box;
    display: block;
    width: 214px;
    max-width: initial;
    height: 161px;
    border: none;
    border-radius: 5px;
    object-fit: cover;
  }
  .hero-banner {
    box-sizing: border-box;
    flex: 0 0 310px;
    max-width: initial;
    height: 581px;
    padding: 25px 29px 488px;
    margin-left: 31px;
    font: 700 40px Inter, sans-serif;
    color: rgba(255, 255, 255, 1);
    white-space: pre-wrap;
    background: url(../assetsImage/assets_catalog/p_41_430_634006.png) no-repeat 50% / cover;
    border: none;
  }
  .contact-section1 {
    width: 100%;
    height: 190px;
  }
  .contact-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(144, 151, 156, 1);
    border-radius: 16px;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .contact-info-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 5%;
  }

  .contact-info-section {
    margin-left: 5%;
    margin-right: 0;
  }
  .contact-message {
    font: 700 32px/36px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-message1 {
    margin-top: 29px;
    font: 400 16px Inter, sans-serif;
    color: rgba(23, 33, 38, 1);
    white-space: pre-wrap;
  }
  .contact-section-banner {
    box-sizing: border-box;
    display: block;
    max-width: initial;
    height: 61px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    .product-card-list-industrial {
      flex-direction: column;
    }

    .transport-heading-style-industrial {
      font: 600 18px / 24px Inter, sans-serif;
      
    }

    .vehicle-image-container-industrial {
      display: flex;
      height: 50%;
  max-width: 40%;
  width: 40%;
      margin-left: 2%;
    }

    .vehicle-image-container-industrial img {
      width: auto;
    }

    .transport-info-card-industrial {
      width: 60%;
      margin-left: 5%;
      padding-right: 3%;
    }
  }